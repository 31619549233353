export const colors = {
  black: '#000',
  blue: '#348EDE',
  green: '#3CCCA2',
  red: '#FF4141',
  white: '#fff',
  yellow: '#FCCF00',
  grayDark: '#e3e6e8',
  gray: '#e6ebf1'
};
