import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import {Patient} from '../types';

export const NEW_INVOICE_PATIENTS_AUTOCOMPLETE_QUERY = gql`
  query NewInvoicePatientsAutocompleteQuery($query: String!) {
    me {
      office {
        patients(first: 10, query: $query) {
          edges {
            node {
              id
              firstName
              lastName
              email
              phone
              address
              state
              postalCode
              city
            }
          }
        }
      }
    }
  }
`;

interface Data {
  me: {
    office: {
      patients: {
        edges: {
          node: Patient;
        }[];
      };
    };
  };
}

export class NewInvoicePatientsAutocompleteQuery extends Query<Data> {}
