import gql from 'graphql-tag';
import {ChildMutateProps} from 'react-apollo';

export const UPDATE_SETTINGS_MUTATION = gql`
  mutation UpdateSettingsMutation($attributes: OfficeInput!) {
    updateOffice(attributes: $attributes) {
      office {
        id
        name
      }
    }
  }
`;

export type UpdateSettingsMutationProps = ChildMutateProps<
  {
    attributes: {
      name: string;
    };
  },
  {
    updateOffice: {
      office: {
        id: string;
        name: string;
      };
    };
  }
>;
