import React from 'react';
import {Route, RouteProps, Redirect} from 'react-router-dom';

export default function ProtectedRoute(props: RouteProps) {
  if (sessionStorage.getItem('token') == null) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
}
