import React from 'react';
import styled from 'react-emotion';
import {graphql, compose} from 'react-apollo';
import {Redirect} from 'react-router';
import {autobind} from 'utilities/autobind';
import {Heading, Input, Label, Stack, spacing} from 'ui-kit';
import {Helmet} from 'react-helmet';

import {Patient} from './types';

import {
  CREATE_INVOICE_MUTATION,
  CreateInvoiceMutationProps
} from './graphql/CreateInvoiceMutation';

import {Summary} from './components/Summary';
import {PatientAutocomplete} from './components/PatientAutocomplete';

enum MutationStatus {
  Loading = 'LOADING',
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

interface State {
  status: MutationStatus | null;
  title: string;
  dueDate: string;
  patient: Patient | null;
  total: string;
  dateOfService: string;
}

const numberFormatter = new Intl.NumberFormat('en', {
  currency: 'usd',
  style: 'currency',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const LayoutContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '5fr 3fr',
  gridColumnGap: spacing.extraLoose * 2
});

function today() {
  const today = new Date();

  return `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
}

function twoWeeksAway() {
  const today = new Date();

  today.setDate(today.getDate() + 14);

  return `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
}

export class InvoicesNew extends React.Component<
  CreateInvoiceMutationProps,
  State
> {
  state: State = {
    status: null,
    title: '',
    dueDate: twoWeeksAway(),
    patient: null,
    total: '',
    dateOfService: today()
  };

  render() {
    const {title, patient, status, dueDate, total, dateOfService} = this.state;

    if (status === MutationStatus.Success) {
      // TODO: This should actually redirect to the invoice detail
      return <Redirect to="/invoices" />;
    }

    return (
      <>
        <Helmet>
          <title>New invoice</title>
        </Helmet>
        <Stack vertical>
          <Heading>New invoice</Heading>

          <LayoutContainer>
            <form onSubmit={this.handleSubmit} method="post">
              <Stack vertical>
                <Stack.Item>
                  <Label>Title</Label>
                  <Input
                    name="title"
                    value={title}
                    onChange={this.handleGenericFieldChange}
                    autoComplete="off"
                  />
                </Stack.Item>

                <Stack.Item>
                  <Label>Date of service</Label>
                  <Input
                    name="dateOfService"
                    value={dateOfService}
                    autoComplete="off"
                    onChange={this.handleGenericFieldChange}
                  />
                </Stack.Item>

                <Stack.Item>
                  <Label>Due date (MM/DD/YYYY)</Label>
                  <Input
                    name="dueDate"
                    value={dueDate}
                    autoComplete="off"
                    onChange={this.handleGenericFieldChange}
                  />
                </Stack.Item>

                <PatientAutocomplete
                  onPatientFieldChange={this.handlePatientFieldChange}
                />

                <Stack.Item>
                  <Label>Total to bill</Label>
                  <Input
                    name="total"
                    value={total}
                    autoComplete="off"
                    onChange={this.handleTotalChange}
                    onBlur={this.handleTotalBlur}
                  />
                </Stack.Item>
              </Stack>
            </form>

            <div>
              <Summary
                title={title}
                patient={patient}
                total={total}
                onInvoiceSubmit={this.handleSubmit}
              />
            </div>
          </LayoutContainer>
        </Stack>
      </>
    );
  }

  @autobind
  private handleSubmit() {
    this.setState({status: MutationStatus.Loading});

    const {mutate} = this.props;
    const {title, patient, dueDate, total, dateOfService} = this.state;

    if (patient == null) {
      throw new Error('Patient cannot be null');
    }

    const safeTotalPrice = parseFloat(total.replace(/[^(\.|\d)]/g, ''));

    mutate({
      variables: {
        attributes: {
          title,
          patientId: patient.id,
          dateOfService,
          dueAt: dueDate,
          invoiceProcedures: [
            {priceCents: safeTotalPrice * 100, procedureCode: 'M0001'}
          ]
        }
      }
    })
      .then(({data: {createInvoice: {invoice}}, errors}) => {
        if (errors != null || invoice == null) {
          this.setState({status: MutationStatus.Failed});
          return;
        }

        this.setState({status: MutationStatus.Success});
      })
      .catch(error => {
        this.setState({status: MutationStatus.Failed});
        throw new Error(error);
      });
  }

  @autobind
  private handleGenericFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {value, name} = event.target;

    // Computed properties don't play super well with typescript right now, so we
    // cast to any. Should replace with some sort of type narrowing later on.
    this.setState({[name as any]: value});
  }

  @autobind
  private handlePatientFieldChange(patient: Patient) {
    this.setState({patient});
  }

  @autobind
  private handleTotalChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {value} = event.target;

    this.setState(() => {
      const cleanNumber = value.replace(/[^(,|\.|\d)|$]/g, '');

      return {
        total: cleanNumber
      };
    });
  }

  @autobind
  private handleTotalBlur(event: React.FocusEvent<HTMLInputElement>) {
    const {value} = event.target;
    const cleanValue = value.replace(/[^(\.|\d)]/g, '');
    const valueAsNumber = parseFloat(cleanValue);
    const formattedValue = isNaN(valueAsNumber)
      ? ''
      : numberFormatter.format(valueAsNumber);

    this.setState({
      total: formattedValue
    });
  }
}

export default compose(graphql(CREATE_INVOICE_MUTATION))(InvoicesNew);
