import gql from 'graphql-tag';
import {ChildMutateProps} from 'react-apollo';

export const SEND_TEXT_MUTATION = gql`
  mutation SendInvoiceReminderText($id: String!) {
    sendInvoiceReminderText(invoiceId: $id) {
      errors
    }
  }
`;

export type SendTextMutationProps = ChildMutateProps<
  {
    id: string;
  },
  {
    sendInvoiceReminderText: {
      errors: string[];
    };
  }
>;
