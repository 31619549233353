import React from 'react';
import styled from 'react-emotion';
import * as icons from '../../icons';

interface Props {
  icon: 'chevronDown' | 'chevronRight';
}

export const IconImg = styled('img')({width: 12});

export function Icon({icon}: Props) {
  return <IconImg src={icons[icon]} />;
}
