import React from 'react';
import styled from 'react-emotion';
import {Link} from 'react-router-dom';
import {Spinner} from '../Spinner';
import {colors} from '../../tokens';

export interface Props {
  children: React.ReactNode;
  href?: string;
  fullWidth?: boolean;
  slim?: boolean;
  onClick?(event: React.MouseEvent<any>): void;
  color?: 'blue';
  loading?: boolean;
  type?: string;
}

const baseStyles: any = {
  backgroundColor: '#fff',
  border: '1px solid transparent',
  borderRadius: 5,
  boxShadow: '0 1px 4px rgba(0,0,0,0.07), 0 1px 1px rgba(0,0,0,0.1)',
  color: colors.blue,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: 15,
  lineHeight: '1.6',
  marginBottom: 0,
  padding: '8px 16px',
  textAlign: 'center',
  textDecoration: 'none',
  touchAction: 'manipulation',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  userSelect: 'none'
};

const linkStyles = {
  ':hover': {
    color: '#777',
    textDecoration: 'none'
  }
};

const fullWidthStyles = {
  display: 'block',
  width: '100%'
};

const slimStyles = {
  fontSize: 14,
  padding: '4px 10px'
};

function computeStyles({fullWidth, slim, color}: Partial<Props>) {
  let computedStyles = {};

  if (fullWidth) {
    computedStyles = {...computedStyles, ...fullWidthStyles};
  }

  if (slim) {
    computedStyles = {...computedStyles, ...slimStyles};
  }

  if (color) {
    switch (color) {
      case 'blue':
        computedStyles = {
          ...computedStyles,
          backgroundColor: colors.blue,
          borderColor: 'transparent',
          color: '#fff',
          ':hover': {
            color: '#fff'
          }
        };
    }
  }

  return computedStyles;
}

const StyledButton = styled('button')<Partial<Props>>(
  {
    ...baseStyles,
    ':focus': {outline: 0}
  },
  computeStyles
);

const StyledExternalLink = styled('a')<Partial<Props>>(
  {
    ...baseStyles,
    ...linkStyles
  },
  computeStyles
);

const StyledInternalLink = styled('span')<Partial<Props>>(
  {
    ...baseStyles,
    ...linkStyles
  },
  computeStyles
);

export function Button({
  href,
  children,
  slim,
  fullWidth,
  onClick,
  color,
  type,
  loading = false
}: Props) {
  if (href != null && onClick != null) {
    throw new Error('Button cannot have both `onClick` and `href` props.');
  }

  const childMarkup = loading ? (
    <Spinner size="small" color="white" />
  ) : (
    children
  );

  if (href == null) {
    return (
      <StyledButton
        slim={slim}
        fullWidth={fullWidth}
        onClick={onClick}
        color={color}
        type={type}
      >
        {childMarkup}
      </StyledButton>
    );
  }

  if (href.startsWith('http')) {
    return (
      <StyledExternalLink
        href={href}
        slim={slim}
        fullWidth={fullWidth}
        color={color}
        target="_blank"
      >
        {childMarkup}
      </StyledExternalLink>
    );
  }

  return (
    <StyledInternalLink slim={slim} fullWidth={fullWidth} color={color}>
      <Link style={{color: 'inherit', textDecoration: 'inherit'}} to={href}>
        {childMarkup}
      </Link>
    </StyledInternalLink>
  );
}
