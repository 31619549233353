import React from 'react';
import styled from 'react-emotion';
import {Helmet} from 'react-helmet';
import {
  Card,
  EmptyStateContainer,
  Heading,
  Spinner,
  Stack,
  colors,
  spacing
} from 'ui-kit';
import {autobind} from 'utilities/autobind';
import {graphql, compose} from 'react-apollo';

import {SettingsQuery, SETTINGS_QUERY} from './graphql/SettingsQuery';
import {
  UPDATE_SETTINGS_MUTATION,
  UpdateSettingsMutationProps
} from './graphql/UpdateSettingsMutation';

import {Form, FormFields} from './components';

enum MutationStatus {
  Loading = 'LOADING',
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

interface State {
  status: MutationStatus | null;
}

const SuccessBanner = styled('div')({
  background: colors.green,
  borderRadius: 6,
  padding: spacing.tight,
  opacity: 0.8
});

const ErrorBanner = styled('div')({
  background: colors.red,
  borderRadius: 6,
  color: colors.white,
  padding: spacing.tight,
  opacity: 0.8
});

type ComposedProps = UpdateSettingsMutationProps;

class Settings extends React.Component<ComposedProps, State> {
  state: State = {
    status: null
  };

  render() {
    const {status} = this.state;

    const successBanner =
      status === MutationStatus.Success ? (
        <SuccessBanner>Settings successfully updated</SuccessBanner>
      ) : null;

    const errorBanner =
      status === MutationStatus.Failed ? (
        <ErrorBanner>Something went wrong. Try again later.</ErrorBanner>
      ) : null;

    return (
      <>
        <Helmet>
          <title>Settings</title>
        </Helmet>

        <Stack vertical>
          <Heading>Settings</Heading>

          {successBanner}
          {errorBanner}

          <SettingsQuery query={SETTINGS_QUERY} fetchPolicy="network-only">
            {({loading, error, data}) => {
              if (loading) {
                return (
                  <EmptyStateContainer>
                    <Spinner size="large" />
                  </EmptyStateContainer>
                );
              }

              if (error || data == null) {
                return (
                  <Card>
                    <EmptyStateContainer>
                      <Heading>An error occurred</Heading>
                    </EmptyStateContainer>
                  </Card>
                );
              }

              return (
                <Card>
                  <Form
                    onSubmit={this.handleSubmit}
                    initialValues={{...data.me.office}}
                  />
                </Card>
              );
            }}
          </SettingsQuery>
        </Stack>
      </>
    );
  }

  @autobind
  private handleSubmit(values: FormFields) {
    const {
      name,
      address1,
      address2,
      city,
      state,
      zip,
      phone,
      email,
      websiteUrl
    } = values;
    const {mutate} = this.props;

    mutate({
      variables: {
        attributes: {
          name,
          address1,
          address2,
          city,
          state,
          zip,
          phone,
          email,
          websiteUrl
        }
      }
    })
      .then(({data: {updateOffice: {office}}, errors}) => {
        if (errors != null || office == null) {
          this.setState({status: MutationStatus.Failed});
          return;
        }

        this.setState({status: MutationStatus.Success});
      })
      .catch(error => {
        this.setState({status: MutationStatus.Failed});
        throw new Error(error);
      });
  }
}

export default compose(graphql(UPDATE_SETTINGS_MUTATION))(Settings);
