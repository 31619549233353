import gql from 'graphql-tag';
import {ChildMutateProps} from 'react-apollo';

export const CREATE_INVOICE_MUTATION = gql`
  mutation CreateInvoiceMutation($attributes: InvoiceInput!) {
    createInvoice(attributes: $attributes) {
      invoice {
        id
      }
    }
  }
`;

export type CreateInvoiceMutationProps = ChildMutateProps<
  {
    attributes: {
      patientId: string;
      dueAt: string;
      title: string;
      invoiceProcedures: {
        priceCents: number;
        procedureCode: string;
      }[];
    };
  },
  {
    createInvoice: {
      invoice: {
        id: string;
      };
    };
  }
>;
