import React from 'react';
import styled from 'react-emotion';
import {logoWhite} from 'ui-kit';

const Logo = styled('img')({
  maxWidth: 70
});

export function PoweredBy() {
  return <Logo src={logoWhite} />;
}
