import React from 'react';
import {Stack} from 'ui-kit';
import {Link} from 'react-router-dom';
import {Invoice} from '../../types';

interface Props {
  invoices: Invoice[];
}

export function InvoiceList({invoices}: Props) {
  return (
    <Stack vertical spacing="tight">
      {invoices.map(invoice => (
        <Link to={`/invoices/${invoice.id}`} key={invoice.id}>
          <p>
            {invoice.number} - {invoice.title}
          </p>
        </Link>
      ))}
    </Stack>
  );
}
