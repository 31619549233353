import React from 'react';
import styled from 'react-emotion';
import {Heading, spacing} from 'ui-kit';
import {Helmet} from 'react-helmet';

const Container = styled('div')({
  padding: `${spacing.loose}px 0`
});

export function NotFound() {
  return (
    <Container>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <Heading>We couldn't find the page you were looking for</Heading>
    </Container>
  );
}
