import {Query} from 'react-apollo';
import gql from 'graphql-tag';

export const SETTINGS_QUERY = gql`
  query Settings {
    me {
      office {
        name
        address1
        address2
        city
        state
        zip
        phone
        websiteUrl
        email
      }
    }
  }
`;

export interface SettingsQueryData {
  me: {
    office: {
      name: string;
      address1: string;
      address2: string;
      city: string;
      state: string;
      zip: string;
      phone: string;
      websiteUrl: string;
      email: string;
    };
  };
}

export class SettingsQuery extends Query<SettingsQueryData> {}
