import React from 'react';
import styled from 'react-emotion';
import {GetItemPropsOptions} from 'downshift';
import {Icon, Stack, Spinner, spacing} from 'ui-kit';
import {
  NEW_INVOICE_PATIENTS_AUTOCOMPLETE_QUERY,
  NewInvoicePatientsAutocompleteQuery
} from '../../../../graphql/NewInvoicePatientsAutocompleteQuery';

import {AutocompleteDropdownContainer} from '../../../AutocompleteDropdownContainer';

interface Props {
  highlightedIndex: number | null;
  inputValue: string | null;
  getItemProps: (options: GetItemPropsOptions) => any;
}

const Caret = styled('p')({
  width: 10
});

const LoadingContainer = styled('div')({
  padding: spacing.base,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export function Dropdown({inputValue, getItemProps, highlightedIndex}: Props) {
  return (
    <NewInvoicePatientsAutocompleteQuery
      query={NEW_INVOICE_PATIENTS_AUTOCOMPLETE_QUERY}
      variables={{query: inputValue || ''}}
      fetchPolicy="network-only"
    >
      {({loading, error, data}) => {
        if (loading) {
          return (
            <AutocompleteDropdownContainer>
              <LoadingContainer>
                <Spinner />
              </LoadingContainer>
            </AutocompleteDropdownContainer>
          );
        }

        if (error || data == null) {
          return (
            <AutocompleteDropdownContainer>
              <p>An error!</p>
            </AutocompleteDropdownContainer>
          );
        }

        const {
          me: {
            office: {patients}
          }
        } = data;

        if (patients.edges.length === 0) {
          return (
            <AutocompleteDropdownContainer>
              <p>No patients found for {inputValue}</p>
            </AutocompleteDropdownContainer>
          );
        }

        return (
          <div>
            <AutocompleteDropdownContainer>
              {patients.edges.map(({node}, index) => (
                <div {...getItemProps({item: node, index})} key={node.id}>
                  <Stack>
                    {index === highlightedIndex ? (
                      <Caret>
                        <Icon icon="chevronRight" />
                      </Caret>
                    ) : (
                      <Caret />
                    )}
                    <p>
                      {node.firstName} {node.lastName}
                    </p>
                  </Stack>
                </div>
              ))}
            </AutocompleteDropdownContainer>
          </div>
        );
      }}
    </NewInvoicePatientsAutocompleteQuery>
  );
}
