import styled from 'react-emotion';
import {colors} from '../../tokens';

export const Row = styled('tr')({
  borderBottom: `1px solid ${colors.grayDark}`,
  cursor: 'pointer',
  '&:hover': {
    background: colors.gray
  },
  '&:last-of-type': {
    borderBottom: 'none'
  }
});
