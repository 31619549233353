import {Query} from 'react-apollo';
import gql from 'graphql-tag';

export const INVOICE_QUERY = gql`
  query Invoice($id: String!) {
    me {
      office {
        invoice(id: $id) {
          id
          title
          number
          createdAt
          dueAt
          dateOfService
          totalPriceCents
          amountOutstandingCents
          paymentStatus

          patient {
            id
            firstName
            lastName
            phone
            email

            invoices(first: 10) {
              edges {
                node {
                  id
                  dueAt
                  number
                  title
                  totalPriceCents
                  createdAt
                  paymentStatus
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface Data {
  me: {
    office: {
      invoice?: {
        id: string;
        title: string;
        number: string;
        createdAt: string;
        dateOfService: string;
        dueAt: string;
        totalPriceCents: number;
        amountOutstandingCents: number;
        paymentStatus: string;

        patient: {
          id: string;
          firstName: string;
          lastName: string;
          phone: string;
          email: string;

          invoices: {
            edges: {
              node: {
                id: string;
                number: string;
                dueAt: string;
                title: string;
                totalPriceCents: number;
                createdAt: string;
                paymentStatus: string;
              };
            }[];
          };
        };
      };
    };
  };
}

export class InvoiceQuery extends Query<Data> {}
