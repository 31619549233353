import React from 'react';
import {colors} from '../../tokens';

interface Props {
  size?: 'small' | 'medium' | 'large';
  color?: 'blue' | 'white';
}

export function Spinner({size = 'medium', color = 'blue'}: Props) {
  let scale;
  let strokeWidth;
  switch (size) {
    case 'small':
      scale = 18;
      strokeWidth = 4;
      break;
    case 'medium':
      scale = 38;
      strokeWidth = 2;
      break;
    case 'large':
      scale = 56;
      strokeWidth = 2;
      break;
    default:
      throw new Error(`Could not find size ${size}`);
  }

  let hexColor;
  switch (color) {
    case 'white':
      hexColor = colors.white;
      break;
    case 'blue':
      hexColor = colors.blue;
      break;
    default:
      throw new Error(`Could not find color ${color}`);
  }

  return (
    <svg
      width={scale}
      height={scale}
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
          <stop stopColor={hexColor} stopOpacity="0" offset="0%" />
          <stop stopColor={hexColor} stopOpacity=".631" offset="63.146%" />
          <stop stopColor={hexColor} offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
          <path
            d="M36 18c0-9.94-8.06-18-18-18"
            id="Oval-2"
            stroke="url(#a)"
            strokeWidth={strokeWidth}
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </path>
          <circle fill={hexColor} cx="36" cy="18" r="1">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </g>
    </svg>
  );
}
