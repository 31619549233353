import styled from 'react-emotion';
import {Link} from 'react-router-dom';
import {spacing} from 'ui-kit';

export const SidebarLink = styled(Link)({
  color: '#dadada',
  fontSize: 15,
  fontWeight: 700,
  paddingBottom: spacing.base,
  transition: 'color 0.2s',

  ':hover': {
    textDecoration: 'none',
    color: '#fafafa'
  }
});
