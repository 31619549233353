import * as React from 'react';
import classnames from 'classnames';

export interface Props {
  children?: React.ReactNode;
  fill?: boolean;
}

export default function Item({children, fill}: Props) {
  const className = classnames('Stack__Item', fill && 'Stack__Item--fill');

  return <div className={className}>{children}</div>;
}
