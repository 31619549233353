import React from 'react';
import {Stack, Label, Input, Button} from 'ui-kit';
import {autobind} from 'utilities/autobind';

export interface FormFields {
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  websiteUrl: string;
  email: string;
}

interface Props {
  initialValues: FormFields;
  onSubmit(values: FormFields): void;
}

interface State extends FormFields {}

export class Form extends React.Component<Props, State> {
  state: State = {
    ...this.props.initialValues
  };

  render() {
    const {
      name,
      address1,
      address2,
      city,
      state,
      zip,
      phone,
      email,
      websiteUrl
    } = this.state;

    return (
      <form method="post" onSubmit={this.handleSubmit}>
        <Stack vertical>
          <Stack.Item>
            <Label>Office name</Label>
            <Input name="name" value={name} onChange={this.handleFieldChange} />
          </Stack.Item>

          <Stack>
            <Stack.Item fill>
              <Label>Email</Label>
              <Input
                name="email"
                value={email}
                onChange={this.handleFieldChange}
              />
            </Stack.Item>

            <Stack.Item fill>
              <Label>Phone number</Label>
              <Input
                name="phone"
                value={phone}
                onChange={this.handleFieldChange}
              />
            </Stack.Item>
          </Stack>

          <Stack>
            <Stack.Item fill>
              <Label>Street Address</Label>
              <Input
                name="address1"
                value={address1}
                onChange={this.handleFieldChange}
              />
            </Stack.Item>

            <Stack.Item fill>
              <Label>Address 2 (Optional)</Label>
              <Input
                name="address2"
                value={address2}
                onChange={this.handleFieldChange}
              />
            </Stack.Item>
          </Stack>

          <Stack>
            <Stack.Item fill>
              <Label>City</Label>
              <Input
                name="city"
                value={city}
                onChange={this.handleFieldChange}
              />
            </Stack.Item>

            <Stack.Item fill>
              <Label>State</Label>
              <Input
                name="state"
                value={state}
                onChange={this.handleFieldChange}
              />
            </Stack.Item>

            <Stack.Item fill>
              <Label>Zip</Label>
              <Input name="zip" value={zip} onChange={this.handleFieldChange} />
            </Stack.Item>
          </Stack>

          <Stack.Item>
            <Label>Website URL</Label>
            <Input
              name="websiteUrl"
              value={websiteUrl}
              onChange={this.handleFieldChange}
            />
          </Stack.Item>

          <Button color="blue" type="submit">
            Save changes
          </Button>
        </Stack>
      </form>
    );
  }

  @autobind
  private handleFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {name, value} = event.target;

    this.setState({[name]: value});
  }

  @autobind
  private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    this.props.onSubmit({...this.state});
  }
}
