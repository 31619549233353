import React, {FormEvent, ChangeEvent} from 'react';
import styled from 'react-emotion';
import {Redirect} from 'react-router-dom';
import {autobind} from 'utilities/autobind';
import {Card, Button, Heading, Label, Input, Stack, spacing} from 'ui-kit';
import {graphql, compose, ChildMutateProps} from 'react-apollo';
import gql from 'graphql-tag';
import {Helmet} from 'react-helmet';

enum LoginStatus {
  Loading = 'LOADING',
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

interface State {
  email: string;
  password: string;
  status: LoginStatus | null;
}

type Props = ChildMutateProps<
  {email: string; password: string},
  {signInUser: {token: string} | null}
>;

const Container = styled('div')({
  margin: '80px auto',
  maxWidth: 500
});

const ErrorBanner = styled('div')({
  borderRadius: 4,
  background: 'lightcoral',
  color: 'white',
  padding: spacing.tight
});

class Login extends React.Component<Props, State> {
  state: State = {
    email: '',
    password: '',
    status: null
  };

  render() {
    const {email, password, status} = this.state;

    if (status === LoginStatus.Success) {
      return <Redirect to="/" />;
    }

    const errorBannerMarkup =
      status === LoginStatus.Failed ? (
        <ErrorBanner>
          <p>Incorrect email or password.</p>
        </ErrorBanner>
      ) : null;

    return (
      <Container>
        <Helmet>
          <title>Log in</title>
        </Helmet>
        <Stack vertical spacing="loose">
          <Heading>Login</Heading>
          <Card spacing="loose">
            <form onSubmit={this.handleSubmit} method="post">
              <Stack vertical>
                {errorBannerMarkup}
                <Stack.Item>
                  <Label>Email</Label>
                  <Input
                    type="text"
                    name="email"
                    value={email}
                    onChange={this.handleEmailChange}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Label>Password</Label>
                  <Input
                    type="password"
                    name="password"
                    value={password}
                    onChange={this.handlePasswordChange}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Button
                    fullWidth
                    color="blue"
                    loading={status === LoginStatus.Loading}
                  >
                    Submit
                  </Button>
                </Stack.Item>
              </Stack>
            </form>
          </Card>
        </Stack>
      </Container>
    );
  }

  @autobind
  private handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const {email, password} = this.state;

    this.setState({status: LoginStatus.Loading});

    this.props
      .mutate({variables: {email, password}})
      .then(({data: {signInUser}}) => {
        if (signInUser == null) {
          this.setState({status: LoginStatus.Failed});
          return;
        }

        const {token} = signInUser;

        sessionStorage.setItem('token', token);
        this.setState({status: LoginStatus.Success});
      });
  }

  @autobind
  private handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    const {value} = event.target;

    this.setState({email: value});
  }

  @autobind
  private handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    const {value} = event.target;

    this.setState({password: value});
  }
}

export default compose(
  graphql(
    gql`
      mutation signInUser($email: String!, $password: String!) {
        signInUser(email: $email, password: $password) {
          token
        }
      }
    `,
    {
      options: {
        context: {
          uri:
            process.env.ENV === 'production'
              ? 'https://molar-221002.appspot.com/auth'
              : 'http://localhost:3000/auth'
        }
      }
    }
  )
)(Login);
