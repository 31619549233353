import React from 'react';
import styled from 'react-emotion';
import Downshift, {StateChangeOptions} from 'downshift';
import {Input, Label} from 'ui-kit';
import {autobind} from 'utilities/autobind';
import {debounce} from 'lodash-decorators';

import {Patient} from '../../types';

import {Dropdown} from './components/Dropdown';

interface Props {
  onPatientFieldChange(patient: Patient): void;
}

interface State {
  searchValue: string;
}

const InputContainer = styled('div')({position: 'relative'});

export class PatientAutocomplete extends React.Component<Props, State> {
  state: State = {
    searchValue: ''
  };

  render() {
    const {searchValue} = this.state;
    return (
      <Downshift
        itemToString={item => item && `${item.firstName} ${item.lastName}`}
        onChange={this.handleChange}
        onStateChange={this.handleStateChange}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          highlightedIndex,
          isOpen
        }) => {
          return (
            <div>
              <Label {...getLabelProps()}>Patient</Label>

              <InputContainer>
                <Input {...getInputProps({style: {position: 'relative'}})} />
                {isOpen ? (
                  <div>
                    <Dropdown
                      inputValue={searchValue}
                      getItemProps={getItemProps}
                      highlightedIndex={highlightedIndex}
                    />
                  </div>
                ) : null}
              </InputContainer>
            </div>
          );
        }}
      </Downshift>
    );
  }

  @autobind
  private handleChange(patient: Patient) {
    this.props.onPatientFieldChange(patient);
  }

  @autobind
  @debounce(300)
  private handleStateChange({inputValue}: Partial<StateChangeOptions>) {
    if (inputValue == null) {
      return;
    }

    this.setState({searchValue: inputValue});
  }
}
