import {Query} from 'react-apollo';
import gql from 'graphql-tag';

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    me {
      office {
        name
      }
    }
  }
`;

interface Data {
  me: {
    office: {
      name: string;
    };
  };
}

export class CurrentUserQuery extends Query<Data> {}
