import styled from 'react-emotion';
import {spacing} from '../../tokens';

export const EmptyStateContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: `${spacing.extraLoose * 2}px 0`
});
