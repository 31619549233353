import styled from 'react-emotion';
import {spacing as spacingTokens} from '../../tokens';

interface Props {
  spacing?: 'tight' | 'loose' | 'none' | 'base';
}

export const Card = styled('div')<Props>(({spacing = 'base'}) => {
  let padding = spacingTokens.base;

  switch (spacing) {
    case 'loose':
      padding = spacingTokens.loose;
      break;
    case 'tight':
      padding = spacingTokens.tight;
      break;
    case 'none':
      padding = 0;
      break;
    case 'base':
      break;
    default:
      throw new Error(
        `Unrecognized spacing option ${spacing} provided to <Card />`
      );
  }

  return {
    background: '#fff',
    border: '1px solid #e0e0e0',
    borderRadius: 6,
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05)',
    padding,
    width: '100%'
  };
});
