import React from 'react';
import {Badge} from 'ui-kit';

interface Props {
  status: string;
}

export function PaymentStatusBadge({status}: Props) {
  switch (status) {
    case 'PAID':
      return <Badge color="green">Paid</Badge>;
    case 'UNPAID':
      return <Badge>Unpaid</Badge>;
    case 'OVERDUE':
      return <Badge color="red">Overdue</Badge>;
    default:
      throw new Error(`Unknown payment status: ${status}`);
  }
}
