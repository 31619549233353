import React from 'react';
import styled from 'react-emotion';
import {Stack, spacing} from 'ui-kit';

import {SidebarLink} from './components/SidebarLink';
import {PoweredBy} from './components/PoweredBy';

import {SIDEBAR_WIDTH} from './../../constants';

interface Props {
  officeName: string | null;
}

const Container = styled('div')({
  background: '#232323',
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  left: 0,
  padding: `${spacing.loose}px ${spacing.base}px`,
  position: 'fixed',
  top: 0,
  width: SIDEBAR_WIDTH,
  zIndex: 1
});

const OfficeNameText = styled('p')({
  color: '#eaeaea',
  fontSize: 13,
  fontWeight: 600
});

const LinkContainer = styled('div')({
  display: 'flex',
  paddingTop: spacing.base,
  flexDirection: 'column'
});

export function Sidebar({officeName}: Props) {
  return (
    <Container>
      <Stack vertical>
        <Stack vertical spacing="tight">
          <PoweredBy />
          <OfficeNameText>{officeName}</OfficeNameText>
        </Stack>

        <LinkContainer>
          <SidebarLink to="/">Home</SidebarLink>
          <SidebarLink to="/invoices">Invoices</SidebarLink>
          <SidebarLink to="/settings">Settings</SidebarLink>
        </LinkContainer>
      </Stack>
    </Container>
  );
}
