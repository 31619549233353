import React from 'react';
import styled from 'react-emotion';
import {Route, Switch} from 'react-router-dom';
import {spacing, boxShadow} from 'ui-kit';

import {CurrentUserQuery, CURRENT_USER_QUERY} from './graphql/CurrentUserQuery';
import {SIDEBAR_WIDTH} from './constants';
import {Sidebar} from './components/Sidebar';

import {Home} from '../../sections/Home';
import {InvoicesIndex} from '../../sections/InvoicesIndex';
import {InvoicesNew} from '../../sections/InvoicesNew';
import {InvoicesShow} from '../../sections/InvoicesShow';
import {Settings} from '../../sections/Settings';
import {NotFound} from '../../sections/NotFound';

const Container = styled('div')({
  height: '100%',
  position: 'relative'
});

const Content = styled('div')({
  background: '#f6f8fa',
  boxShadow: boxShadow.base,
  padding: `${spacing.loose}px`,
  marginLeft: SIDEBAR_WIDTH,
  minHeight: '100%'
});

export default function Frame() {
  return (
    <Container>
      <CurrentUserQuery query={CURRENT_USER_QUERY} fetchPolicy="network-only">
        {({loading, error, data}) => {
          if (loading || error || data == null) {
            return <Sidebar officeName={null} />;
          }

          return <Sidebar officeName={data.me.office.name} />;
        }}
      </CurrentUserQuery>

      <Content>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/invoices" component={InvoicesIndex} />
          <Route exact path="/invoices/new" component={InvoicesNew} />
          <Route exact path="/invoices/:id" component={InvoicesShow} />
          <Route exact path="/settings" component={Settings} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Content>
    </Container>
  );
}
