import React from 'react';
import {Card, Heading, Stack, EmptyStateContainer, Spinner} from 'ui-kit';
import {nodesFromEdges} from 'utilities';

import {HomeQuery, HOME_QUERY} from './graphql/HomeQuery';
import {InvoiceList, Metric} from './components';

export function Home() {
  return (
    <Stack vertical spacing="extraLoose">
      <Heading>Welcome back!</Heading>

      <HomeQuery query={HOME_QUERY} fetchPolicy="network-only">
        {({loading, error, data}) => {
          if (loading) {
            return (
              <EmptyStateContainer>
                <Spinner size="large" />
              </EmptyStateContainer>
            );
          }

          if (error || data == null) {
            console.log(error, data);
            return (
              <EmptyStateContainer>
                <Heading>An error occurred</Heading>
              </EmptyStateContainer>
            );
          }

          const {
            totalCollected,
            totalOutstanding,
            totalOverdue,
            paidInvoices,
            overdueInvoices,
            unpaidInvoices
          } = data.me.office;

          return (
            <Stack spacing="loose">
              <Stack.Item fill>
                <Card>
                  <Stack vertical spacing="loose">
                    <Metric
                      title="Total collected"
                      subtitle="Last 30 days"
                      amount={totalCollected / 100}
                      color={totalCollected > 0 ? 'green' : 'black'}
                    />

                    <Stack vertical spacing="extraTight">
                      <p>
                        <strong>Recent paid invoices</strong>
                      </p>

                      <InvoiceList invoices={nodesFromEdges(paidInvoices)} />
                    </Stack>
                  </Stack>
                </Card>
              </Stack.Item>

              <Stack.Item fill>
                <Card>
                  <Stack vertical spacing="loose">
                    <Metric
                      title="Total outstanding"
                      subtitle="All current unpaid invoices"
                      amount={totalOutstanding / 100}
                      color={totalOutstanding > 0 ? 'red' : 'black'}
                    />

                    <Stack vertical spacing="extraTight">
                      <p>
                        <strong>Recent unpaid invoices</strong>
                      </p>

                      <InvoiceList invoices={nodesFromEdges(unpaidInvoices)} />
                    </Stack>
                  </Stack>
                </Card>
              </Stack.Item>

              <Stack.Item fill>
                <Card>
                  <Stack vertical spacing="loose">
                    <Metric
                      title="Total overdue"
                      subtitle="All overdue invoices"
                      amount={totalOverdue / 100}
                      color={totalOverdue > 0 ? 'red' : 'black'}
                    />

                    <Stack vertical spacing="extraTight">
                      <p>
                        <strong>Recent overdue invoices</strong>
                      </p>

                      <InvoiceList invoices={nodesFromEdges(overdueInvoices)} />
                    </Stack>
                  </Stack>
                </Card>
              </Stack.Item>
            </Stack>
          );
        }}
      </HomeQuery>
    </Stack>
  );
}
