import gql from 'graphql-tag';
import {ChildMutateProps} from 'react-apollo';

export const SEND_REMINDER_EMAIL_MUTATION = gql`
  mutation SendInvoiceReminderText($id: String!) {
    sendInvoiceReminderEmail(invoiceId: $id) {
      errors
    }
  }
`;

export type SendReminderEmailMutationProps = ChildMutateProps<
  {
    id: string;
  },
  {
    sendInvoiceReminderEmail: {
      errors: string[];
    };
  }
>;
