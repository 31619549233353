import styled from 'react-emotion';
import {colors} from '../../tokens';

interface Props {
  color?: 'red' | 'green' | 'blue';
}

export const Badge = styled('span')<Props>(({color}) => {
  const background = color == null ? colors.gray : colors[color];

  return {
    background,
    borderRadius: 10,
    color: color == null ? 'inherit' : colors.white,
    fontSize: 13,
    padding: '3px 10px',
    verticalAlign: 'middle'
  };
});
