import React from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  Card,
  EmptyStateContainer,
  Heading,
  Stack,
  Spinner,
  Table
} from 'ui-kit';
import {Helmet} from 'react-helmet';

import {PaymentStatusBadge} from '../components/PaymentStatusBadge';
import {InvoiceStatusButton, NoInvoicesMessage} from './components';
import {InvoicesQuery, INVOICES_QUERY} from './graphql/InvoicesQuery';
import {autobind, formatCurrency} from 'utilities';

enum InvoiceStatus {
  All = 'ALL',
  Paid = 'PAID',
  Unpaid = 'UNPAID',
  Overdue = 'OVERDUE'
}

interface State {
  invoiceStatus: InvoiceStatus;
}

export class InvoicesIndex extends React.Component<{}, State> {
  state: State = {
    invoiceStatus: InvoiceStatus.All
  };

  render() {
    const {invoiceStatus} = this.state;

    const statusButtonMarkup = Object.keys(InvoiceStatus).map(status => (
      <InvoiceStatusButton
        key={status}
        active={status.toLowerCase() === invoiceStatus.toLowerCase()}
        onClick={() => this.handleStatusButtonClick(status)}
      >
        {status}
      </InvoiceStatusButton>
    ));

    return (
      <>
        <Helmet>
          <title>Invoices</title>
        </Helmet>
        <Stack vertical spacing="loose">
          <Heading>Invoices</Heading>

          <Button href="/invoices/new" slim color="blue">
            New invoice
          </Button>

          <Card>
            <Stack vertical spacing="none">
              <Stack spacing="tight">{statusButtonMarkup}</Stack>

              <InvoicesQuery
                query={INVOICES_QUERY}
                fetchPolicy="network-only"
                variables={{
                  status:
                    invoiceStatus === InvoiceStatus.All ? null : invoiceStatus
                }}
              >
                {({loading, error, data}) => {
                  if (loading) {
                    return (
                      <EmptyStateContainer>
                        <Spinner />
                      </EmptyStateContainer>
                    );
                  }

                  if (error || data == null) {
                    console.error(error);
                    return (
                      <EmptyStateContainer>
                        <Heading>An error occurred</Heading>
                      </EmptyStateContainer>
                    );
                  }

                  const {
                    me: {
                      office: {invoices}
                    }
                  } = data;

                  if (invoices.edges.length === 0) {
                    return <NoInvoicesMessage />;
                  }

                  return (
                    <Stack vertical spacing="tight">
                      <Table>
                        <thead>
                          <Table.HeaderRow>
                            <Table.HeaderCell>Number</Table.HeaderCell>
                            <Table.HeaderCell>Date created</Table.HeaderCell>
                            <Table.HeaderCell>Title</Table.HeaderCell>
                            <Table.HeaderCell>Patient</Table.HeaderCell>
                            <Table.HeaderCell>Total due</Table.HeaderCell>
                            <Table.HeaderCell>Due date</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                          </Table.HeaderRow>
                        </thead>
                        <tbody>
                          {invoices.edges.map(
                            ({
                              node: {
                                id,
                                title,
                                totalPriceCents,
                                dueAt,
                                createdAt,
                                patient,
                                number,
                                paymentStatus
                              }
                            }) => {
                              return (
                                <Table.Row key={id}>
                                  <Table.Cell>#{number}</Table.Cell>
                                  <Table.Cell>{createdAt}</Table.Cell>
                                  <Table.Cell>
                                    <Link to={`/invoices/${id}`}>{title}</Link>
                                  </Table.Cell>
                                  <Table.Cell>
                                    {patient.firstName} {patient.lastName}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {formatCurrency(totalPriceCents / 100)}
                                  </Table.Cell>
                                  <Table.Cell>{dueAt}</Table.Cell>
                                  <Table.Cell>
                                    <PaymentStatusBadge
                                      status={paymentStatus}
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </Stack>
                  );
                }}
              </InvoicesQuery>
            </Stack>
          </Card>
        </Stack>
      </>
    );
  }

  @autobind
  private handleStatusButtonClick(status: string) {
    this.setState({invoiceStatus: status.toUpperCase()});
  }
}
