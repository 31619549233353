import {Query} from 'react-apollo';
import gql from 'graphql-tag';

export const INVOICES_QUERY = gql`
  query Invoices($status: PaymentStatus) {
    me {
      office {
        invoices(first: 10, status: $status) {
          edges {
            node {
              id
              title
              dueAt
              totalPriceCents
              number
              createdAt
              paymentStatus
              patient {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

interface Data {
  me: {
    office: {
      invoices: {
        edges: {
          node: {
            id: string;
            title: string;
            dueAt: string;
            totalPriceCents: number;
            number: number;
            createdAt: string;
            paymentStatus: 'PAID' | 'UNPAID' | 'OVERDUE';
            patient: {
              id: string;
              firstName: string;
              lastName: string;
            };
          };
        }[];
      };
    };
  };
}

export class InvoicesQuery extends Query<Data> {}
