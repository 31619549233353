import React from 'react';
import {ApolloClient} from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {setContext} from 'apollo-link-context';
import {onError} from 'apollo-link-error';
import {InMemoryCache, NormalizedCacheObject} from 'apollo-cache-inmemory';
import {ApolloProvider} from 'react-apollo';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {autobind} from 'utilities/autobind';
import {Helmet} from 'react-helmet';
import ProtectedRoute from './components/ProtectedRoute';
import Frame from './components/Frame';

import Login from './sections/Login';
import Logout from './sections/Logout';

interface State {
  authenticated: boolean;
}

export default class App extends React.Component<{}, State> {
  state: State = {
    authenticated: true
  };

  private client: ApolloClient<NormalizedCacheObject>;

  constructor(props: never) {
    super(props);

    const uri =
      process.env.ENV === 'production'
        ? 'https://molar-221002.appspot.com/graphql'
        : 'http://localhost:3000/graphql';

    const httpLink = createHttpLink({uri});
    const authLink = setContext((_, {headers}) => {
      const token = sessionStorage.getItem('token');

      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        }
      };
    });
    const errorLink = onError(({networkError}) => {
      if (networkError == null) {
        return;
      }

      if ((networkError as any).statusCode === 401) {
        sessionStorage.removeItem('token');
        this.updateAuth(false);
      }
    });

    this.client = new ApolloClient({
      link: authLink.concat(errorLink).concat(httpLink),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only'
        }
      }
    });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Office admin</title>
        </Helmet>
        <Router>
          <ApolloProvider client={this.client}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              <ProtectedRoute path="/" component={Frame} />
            </Switch>
          </ApolloProvider>
        </Router>
      </>
    );
  }

  @autobind
  private updateAuth(authenticated: boolean) {
    this.setState({authenticated});
  }
}
