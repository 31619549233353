import React from 'react';
import styled from 'react-emotion';
import {Button, Stack, Subheading, colors, spacing} from 'ui-kit';

import {Patient} from '../../types';

interface Props {
  title: string | null;
  patient: Patient | null;
  total: string | null;
  onInvoiceSubmit(): void;
}

const Container = styled('div')({
  background: colors.gray,
  borderRadius: 6,
  padding: spacing.base
});

const MissingDataText = styled('p')({
  color: '#777',
  fontStyle: 'italic'
});

export function Summary({title, patient, total, onInvoiceSubmit}: Props) {
  const titleMarkup =
    title == null || title === '' ? (
      <MissingDataText>No title</MissingDataText>
    ) : (
      <p>{title}</p>
    );

  const patientMarkup =
    patient == null ? (
      <MissingDataText>No patient selected</MissingDataText>
    ) : (
      <address>
        {patient.firstName} {patient.lastName} <br />
        {patient.address == null ? null : (
          <>
            {patient.address} <br />
            {patient.city}, {patient.state} {patient.postalCode} <br />
          </>
        )}
        {patient.phone} <br />
        {patient.email}
      </address>
    );

  return (
    <Container>
      <Stack spacing="loose" vertical>
        <Subheading>Invoice Summary</Subheading>

        {titleMarkup}

        <Stack.Item>
          <strong>Bill to</strong>
          {patientMarkup}
        </Stack.Item>

        <Stack alignment="trailing">
          <Stack.Item fill>
            <strong>Total</strong>
          </Stack.Item>
          <Subheading>{total}</Subheading>
        </Stack>

        <Button color="blue" fullWidth type="submit" onClick={onInvoiceSubmit}>
          Send invoice
        </Button>
      </Stack>
    </Container>
  );
}
