import styled from 'react-emotion';
import {colors} from 'ui-kit';

interface Props {
  active: boolean;
  onClick(): void;
}

export const InvoiceStatusButton = styled('button')<Props>(({active}) => ({
  background: 'none',
  border: 0,
  borderBottom: `3px solid ${active ? colors.blue : 'transparent'}`,
  color: active ? '#000' : '#AAA',
  cursor: 'pointer',

  ':hover': {
    color: '#000'
  }
}));
