import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import {Invoice} from '../types';

export const HOME_QUERY = gql`
  query HomeQuery {
    me {
      office {
        totalCollected: totalCollectedLastThirtyDays
        totalOutstanding
        totalOverdue

        paidInvoices: invoices(last: 10, status: PAID) {
          edges {
            node {
              id
              number
              title
            }
          }
        }

        unpaidInvoices: invoices(last: 10, status: UNPAID) {
          edges {
            node {
              id
              number
              title
            }
          }
        }

        overdueInvoices: invoices(last: 10, status: OVERDUE) {
          edges {
            node {
              id
              number
              title
            }
          }
        }
      }
    }
  }
`;

interface Data {
  me: {
    office: {
      totalCollected: number;
      totalOutstanding: number;
      totalOverdue: number;
      paidInvoices: {edges: {node: Invoice}[]};
      unpaidInvoices: {edges: {node: Invoice}[]};
      overdueInvoices: {edges: {node: Invoice}[]};
    };
  };
}

export class HomeQuery extends Query<Data> {}
