import './Stack.css';
import * as React from 'react';
import classnames from 'classnames';
import Item from './Item';

export type Spacing = 'extraTight' | 'tight' | 'loose' | 'extraLoose' | 'none';
export type Alignment = 'leading' | 'trailing' | 'center' | 'fill' | 'baseline';
export type Distribution =
  | 'equalSpacing'
  | 'leading'
  | 'trailing'
  | 'center'
  | 'fill'
  | 'fillEvenly';

export interface Props {
  /** Elements to display inside stack. Must be instances of `Stack.Item` */
  children?: any;
  /** Wrap stack elements to additional rows as needed on small screens (Defaults to true) */
  wrap?: boolean;
  /** Stack the elements vertically */
  vertical?: boolean;
  /** Adjust spacing between elements */
  spacing?: Spacing;
  /** Adjust vertical alignment of elements */
  alignment?: Alignment;
  /** Adjust horizontal alignment of elements */
  distribution?: Distribution;
}

export class Stack extends React.PureComponent<Props, never> {
  static Item = Item;

  render() {
    const {
      children,
      vertical,
      spacing,
      distribution,
      alignment,
      wrap
    } = this.props;

    const className = classnames(
      'Stack',
      vertical && 'Stack--vertical',
      spacing && `Stack--spacing-${spacing}`,
      distribution && `Stack--distribution-${distribution}`,
      alignment && `Stack--alignment-${alignment}`,
      wrap === false && `Stack--noWrap`
    );

    const childMarkup = React.Children.map(children, child => {
      if (child == null) {
        return child;
      }

      if (React.isValidElement(child) && child.type === Item) {
        return child;
      }

      return <Item>{child}</Item>;
    });

    return <div className={className}>{childMarkup}</div>;
  }
}
