import styled from 'react-emotion';
import {spacing, boxShadow} from 'ui-kit';

export const AutocompleteDropdownContainer = styled('div')({
  background: '#fff',
  border: '1px solid #ddd',
  borderRadius: 6,
  boxShadow: boxShadow.base,
  left: 0,
  padding: spacing.base,
  position: 'absolute',
  right: 0,
  top: 40,
  zIndex: 2
});
