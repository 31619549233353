import React from 'react';
import {Button, EmptyStateContainer, Stack} from 'ui-kit';

export function NoInvoicesMessage() {
  return (
    <EmptyStateContainer>
      <Stack vertical alignment="center" distribution="center">
        <Stack.Item>
          <p>No invoices found</p>
        </Stack.Item>
        <Stack.Item>
          <Button slim color="blue" href="/invoices/new">
            Create a new invoice
          </Button>
        </Stack.Item>
      </Stack>
    </EmptyStateContainer>
  );
}
