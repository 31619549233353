import React from 'react';
import {Heading, Stack, Subheading, colors} from 'ui-kit';
import {formatCurrency} from 'utilities';

interface Props {
  title: string;
  amount: number;
  subtitle?: string;
  color?: 'red' | 'green' | 'black';
}

export function Metric({title, subtitle, amount, color = 'black'}: Props) {
  const amountMarkup = (
    <Heading>
      <span style={{color: colors[color]}}>{formatCurrency(amount)}</span>
    </Heading>
  );

  const subtitleMarkup = subtitle == null ? null : <small>{subtitle}</small>;

  return (
    <Stack vertical>
      <Stack.Item>
        <Subheading>{title}</Subheading>
        {subtitleMarkup}
      </Stack.Item>

      {amountMarkup}
    </Stack>
  );
}
