import styled from 'react-emotion';

export const Avatar = styled('div')({
  alignItems: 'center',
  background: '#777',
  borderRadius: '100%',
  color: '#fff',
  display: 'flex',
  fontSize: 14,
  height: 25,
  justifyContent: 'center',
  width: 25,
  userSelect: 'none'
});
