import styled from 'react-emotion';

export const Input = styled('input')({
  border: '1px solid #ccc',
  borderRadius: 6,
  display: 'block',
  padding: '8px 12px',
  transition: 'border 0.2s',
  width: '100%',

  '&:focus': {
    border: '1px solid #444',
    outline: 0
  }
});
