import React from 'react';
import styled from 'react-emotion';
import {Helmet} from 'react-helmet';
import {RouteComponentProps, Link} from 'react-router-dom';
import {graphql, compose} from 'react-apollo';
import {
  Button,
  Card,
  EmptyStateContainer,
  Stack,
  Heading,
  Subheading,
  Table,
  Spinner,
  spacing
} from 'ui-kit';
import {formatCurrency} from 'utilities';

import {INVOICE_QUERY, InvoiceQuery} from './graphql/InvoiceQuery';
import {
  SEND_TEXT_MUTATION,
  SendTextMutationProps
} from './graphql/SendTextMutation';
import {
  SEND_REMINDER_EMAIL_MUTATION,
  SendReminderEmailMutationProps
} from './graphql/SendReminderEmailMutation';
import {PaymentStatusBadge} from '../components/PaymentStatusBadge';

const Breadcrumb = styled(Link)({
  color: '#555555',
  transition: 'color 0.2s',

  '&:hover': {
    color: '#000'
  }
});

const PageLayout = styled('div')({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  gridColumnGap: spacing.loose
});

function formatUtcDate(date: string) {
  const parsedDate = new Date(date);

  return `${parsedDate.getMonth() +
    1}/${parsedDate.getDate()}/${parsedDate.getFullYear()}`;
}

type ComposedProps = RouteComponentProps<{id: string}> & {
  sendText: SendTextMutationProps['mutate'];
} & {
  sendReminderEmail: SendReminderEmailMutationProps['mutate'];
};

function InvoicesShow({match, sendText, sendReminderEmail}: ComposedProps) {
  const invoiceId = match.params.id;

  return (
    <>
      <Helmet>
        <title>Invoice Details</title>
      </Helmet>

      <InvoiceQuery
        query={INVOICE_QUERY}
        fetchPolicy="network-only"
        variables={{id: invoiceId}}
      >
        {({loading, error, data}) => {
          if (loading) {
            return (
              <EmptyStateContainer>
                <Spinner size="large" />
              </EmptyStateContainer>
            );
          }

          if (error || data == null) {
            console.error(error);
            return (
              <Card>
                <EmptyStateContainer>
                  <Heading>An error occurred</Heading>
                </EmptyStateContainer>
              </Card>
            );
          }

          const {invoice} = data.me.office;

          if (invoice == null) {
            return (
              <Card>
                <EmptyStateContainer>
                  <Stack vertical alignment="center" distribution="center">
                    <Stack.Item>
                      <Subheading>We could not find that invoice.</Subheading>
                    </Stack.Item>
                    <Stack.Item>
                      <Button slim color="blue" href="/invoices">
                        Back to all invoices
                      </Button>
                    </Stack.Item>
                  </Stack>
                </EmptyStateContainer>
              </Card>
            );
          }

          const textReminderButtonMarkup =
            invoice.amountOutstandingCents > 0 ? (
              <Button
                slim
                color="blue"
                onClick={() => sendText({variables: {id: invoice.id}})}
              >
                Send reminder text
              </Button>
            ) : null;

          const emailReminderButtonMarkup =
            invoice.amountOutstandingCents > 0 ? (
              <Button
                slim
                color="blue"
                onClick={() => sendReminderEmail({variables: {id: invoice.id}})}
              >
                Send reminder email
              </Button>
            ) : null;

          return (
            <>
              <Helmet>
                <title>Invoice Details | {invoice.title}</title>
              </Helmet>
              <Stack vertical spacing="extraLoose">
                <Stack vertical>
                  <Breadcrumb to="/invoices">&larr; All invoices</Breadcrumb>
                  <Heading>
                    #{invoice.number}{' '}
                    <PaymentStatusBadge status={invoice.paymentStatus} /> <br />
                    <small>{invoice.title}</small>
                  </Heading>
                  <Stack>
                    {textReminderButtonMarkup}
                    {emailReminderButtonMarkup}
                  </Stack>
                </Stack>

                <PageLayout>
                  <Card>
                    <Stack vertical>
                      <Subheading>Invoice info</Subheading>
                      <p>
                        <strong>Invoice issued:</strong>{' '}
                        {formatUtcDate(invoice.createdAt)} <br />
                        <strong>Invoice due:</strong>{' '}
                        {formatUtcDate(invoice.dueAt)} <br />
                        <strong>Date of service:</strong>{' '}
                        {invoice.dateOfService}
                        <br />
                        <strong>Total amount invoiced:</strong>{' '}
                        {formatCurrency(invoice.totalPriceCents / 100)}
                        <br />
                        <strong>Amount outstanding:</strong>{' '}
                        {formatCurrency(invoice.amountOutstandingCents / 100)}
                      </p>
                    </Stack>
                  </Card>

                  <Card>
                    <Stack vertical>
                      <Subheading>Patient info</Subheading>
                      <p>
                        <strong>
                          {invoice.patient.firstName} {invoice.patient.lastName}
                        </strong>{' '}
                        <br />
                        {invoice.patient.phone} <br />
                        {invoice.patient.email}
                      </p>
                    </Stack>
                  </Card>
                </PageLayout>

                <Card>
                  <Stack vertical>
                    <Subheading>Other invoices for this patient</Subheading>
                    <Table>
                      <thead>
                        <Table.HeaderRow>
                          <Table.HeaderCell />
                          <Table.HeaderCell>Date created</Table.HeaderCell>
                          <Table.HeaderCell>Title</Table.HeaderCell>
                          <Table.HeaderCell>Total due</Table.HeaderCell>
                          <Table.HeaderCell>Due date</Table.HeaderCell>
                          <Table.HeaderCell>Status</Table.HeaderCell>
                        </Table.HeaderRow>
                      </thead>
                      <tbody>
                        {invoice.patient.invoices.edges.map(({node}) => (
                          <Table.Row key={node.id}>
                            <Table.Cell>#{node.number}</Table.Cell>
                            <Table.Cell>
                              {formatUtcDate(node.createdAt)}
                            </Table.Cell>
                            <Table.Cell>
                              <Link to={`/invoices/${node.id}`}>
                                {node.title}
                              </Link>
                            </Table.Cell>
                            <Table.Cell>
                              {formatCurrency(node.totalPriceCents / 100)}
                            </Table.Cell>
                            <Table.Cell>{formatUtcDate(node.dueAt)}</Table.Cell>
                            <Table.Cell>
                              <PaymentStatusBadge status={node.paymentStatus} />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </tbody>
                    </Table>
                  </Stack>
                </Card>
              </Stack>
            </>
          );
        }}
      </InvoiceQuery>
    </>
  );
}

export default compose(
  graphql(SEND_TEXT_MUTATION, {name: 'sendText'}),
  graphql(SEND_REMINDER_EMAIL_MUTATION, {name: 'sendReminderEmail'})
)(InvoicesShow);
