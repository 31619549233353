interface EdgeLike<T> {
  edges: {
    node: T;
  }[];
}

export function nodesFromEdges<T>(edgeLike: EdgeLike<T>) {
  return edgeLike.edges.reduce<T[]>((nodes, edge) => {
    return nodes.concat(edge.node);
  }, []);
}
