import React from 'react';
import styled from 'react-emotion';

import {HeaderRow} from './HeaderRow';
import {HeaderCell} from './HeaderCell';
import {Cell} from './Cell';
import {Row} from './Row';

const StyledTable = styled('table')({
  width: '100%'
});

export class Table extends React.PureComponent {
  static HeaderRow = HeaderRow;
  static HeaderCell = HeaderCell;
  static Cell = Cell;
  static Row = Row;

  render() {
    const {children} = this.props;

    return <StyledTable>{children}</StyledTable>;
  }
}
