const defaultNumberFormat = new Intl.NumberFormat('en');
export function formatNumber(
  amount: number,
  options?: Intl.NumberFormatOptions
) {
  if (options != null) {
    return new Intl.NumberFormat('en', options).format(amount);
  }

  return defaultNumberFormat.format(amount);
}

const defaultCurrencyFormat = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'USD'
});
export function formatCurrency(
  amount: number,
  options?: Intl.NumberFormatOptions
) {
  if (options != null) {
    return new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'USD',
      ...options
    }).format(amount);
  }

  return defaultCurrencyFormat.format(amount);
}
